import { Box } from '@mui/material';
import React from 'react'
import Posts from '../../../components/Views/posts/posts';

const PostsPage = () => {
    return (
        <Box m={6}>
            <Posts />
        </Box>
    )
}

export default PostsPage;