import { Box } from '@mui/material';
import React from 'react' 
import ReportedPosts from '../../../components/Views/reports/posts/reportedPosts';

const ReportedPostsPage = () => {
    return (
        <Box m={6}>
            <ReportedPosts />
        </Box>
    )
}

export default ReportedPostsPage;