import { Box } from '@mui/material';
import React from 'react' 
import Users from '../../../components/Views/users/users';

const UsersPage = () => {
    return (
        <Box m={6}>
            <Users />
        </Box>
    )
}

export default UsersPage;