import { Box } from '@mui/material';
import React from 'react' 
import ReportedUsers from '../../../components/Views/reports/users/reportedUsers';
 
const ReportedUsersPage = () => {
    return (
        <Box m={6}>
            <ReportedUsers />
        </Box>
    )
}

export default ReportedUsersPage;