import React, { useState } from 'react';
import { Button, Typography, Box } from '@mui/material';
import MainCard from '../../../ui-elements/cards/MainCard';
import { getFunctions, httpsCallable } from 'firebase/functions';
import * as Yup from 'yup';
import {
    FormControl, 
} from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui'
import { makeStyles } from 'tss-react/mui';

const NotificationPage = () => {
    const [message, setMessage] = useState('');
    const { classes } = useStyles();

    const handleSendNotification = async ({ title, body },
        { setSubmitting }) => {
        try {
            const functions = getFunctions();

            const callableReturnMessage = httpsCallable(functions, 'sendNotificationToAllUsers');

            callableReturnMessage({ title, body }).then((response) => {

                setMessage(response.data);


            }).catch((error) => {
                setSubmitting(false);
                console.log(error);
            });


        } catch (error) {
            console.error('Error sending notification:', error);
            setMessage('Error sending notification');
        }
    };

    return (
        <Box m={6}>
            <MainCard>
                <Formik
                    initialValues={{
                        title: '',
                        body: '',
                    }}

                    validationSchema={Yup.object().shape({
                        title: Yup.string().min(2).max(30).required(),
                        body: Yup.string().min(2).max(100).required()

                    })}
                    onSubmit={(
                        { title, body }, { setSubmitting }
                    ) => { handleSendNotification({ title, body }, { setSubmitting }) }}
                >

                    {({ values, handleBlur, handleChange, touched, errors, setFieldValue
                    }) => (
                        <Form>

                            <Typography variant="h4">Send Notification :</Typography>

                            <FormControl fullWidth className={classes.formInput}>
                                <Field
                                    id="outlined-Title"
                                    type="text"
                                    value={values.displayName}
                                    variant="outlined"
                                    name="title"
                                    label="Title"
                                    onChange={handleChange}
                                    fullWidth
                                    component={TextField}

                                />

                            </FormControl>
                            <FormControl fullWidth className={classes.formInput}>
                                <Field
                                    id="outlined-Body"
                                    type="text"
                                    value={values.body}
                                    variant="outlined"
                                    name="body"
                                    label="Body"
                                    onChange={handleChange}
                                    fullWidth
                                    component={TextField}
                                    multiline
                                    rows={4} 
                                />
                            
                            </FormControl>
                            <Button variant="contained" color="primary" type="submit" >
                                Send notification to all users
                            </Button>
                            {message && (
                                <Box mt={2}>
                                    <Typography variant="body1">{message}</Typography>
                                </Box>
                            )}

                        </Form>

                    )}

                </Formik>
            </MainCard>
        </Box>
    );
};

const useStyles = makeStyles({ "name": { NotificationPage } })(theme => ({
    formInput: {
        ...theme.typography.customInput,
        marginBottom: '10px !important'
    },
    avatar: {
        margin: 'auto',

        width: '100px!important',
        height: '100px!important'

    },
    dialogHeader: {
        paddingBottom: '50px!important'
    }

}));
export default NotificationPage;
